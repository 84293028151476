import './accordion.scss';

class Accordion {
    constructor (element) {
        this.$accordion = element;
        this.$accordionItems = this.$accordion.querySelectorAll('[data-accordion="input"]');
    }

    init () {
        this.setActiveTab();
    }

    setActiveTab () {
        this.$accordionItems.forEach((item) => {
            item.addEventListener('change', (event) => {
                const targetCheckbox = event.target;
                if (targetCheckbox.checked) {
                    this.$accordionItems.forEach((otherCheckbox) => {
                        if (otherCheckbox !== targetCheckbox) {
                            otherCheckbox.checked = false;
                        }
                    });
                }
            });
        });
    }
}

export { Accordion };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $accordion = $context.querySelectorAll('[data-accordion="root"]');
        for (let i = 0; i < $accordion.length; i++) {
            const accordion = new Accordion($accordion[i]);
            accordion.init($accordion[i]);
        }
    }
});
